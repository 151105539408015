import { useEffect, useMemo, useState } from "react";
import estimateGasEth from "../lib/estimateGasEth";

export default function useEstimatedGas() {
  const [value, setValue] = useState(null);

  useEffect(() => {
    estimateGasEth().then((estimated) => {
      if (estimated != -1) {
        setValue(estimated);
      }
    });
  }, []);

  return {
    value: value,
    loading: value === null,
  };
}
