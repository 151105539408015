import React, { useCallback, useMemo, useState } from "react";
import { useWallet } from "use-wallet";
import Modal from "./Modal/Modal";
import MetamaskLogo from "../images/metamask.svg";
import WalletConnectLogo from "../images/walletconnect.svg";

import * as styles from "./mintButton.module.scss";

export const MintButton = ({ label, onClick, loading }) => {
  const wallet = useWallet();
  const [openModal, setOpenModal] = useState(false);

  const active = useMemo(() => wallet.status === "connected", [wallet.status]);

  const metamaskConnect = useCallback(() => {
    if (!active) {
      wallet.connect("injected").then(() => setOpenModal(false));
    }
  }, [active, wallet]);

  const walletconnect = useCallback(() => {
    if (!active) {
      wallet.connect("walletconnect").then(() => setOpenModal(false));
    }
  }, [active, wallet]);

  console.log("openModal", openModal);

  return (
    <>
      <button
        className="mx-2 relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800"
        onClick={
          active
            ? onClick
            : () => {
                setOpenModal(true);
              }
        }
        disabled={loading}
      >
        {/* <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> */}
        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
          {active ? label : "Connect Wallet"}
        </span>
      </button>

      {openModal && (
        <Modal closeModal={() => setOpenModal(false)}>
          <div className={styles.mintButtonModal}>
            <hr />
            {window?.web3 && (
              <button
                onClick={metamaskConnect}
                className="flex justify-start px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
              >
                <img src={MetamaskLogo} width="50px" />
                <span className="text-lg">Metamask</span>
              </button>
            )}

            <hr />
            <button
              onClick={walletconnect}
              className="flex justify-start px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
            >
              <img src={WalletConnectLogo} width="50px" />
              <span className="text-lg">Wallet Connect</span>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};
