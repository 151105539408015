const backendUrl = process.env.GATSBY_BACKEND_URL as string;
const bucketUrl = process.env.GATSBY_IMAGE_BUCKET_URL as string;

export const getWinningCard = async (currentWinner = undefined) => {
  const res = await fetch(`${backendUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ previousCard: currentWinner }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const result = await res.json();

  const randomCards = result?.randomCards?.map((card) => ({
    ...card,
    src: `${bucketUrl}${card.fileName}.jpg`,
  }));

  const winningCard = result.winningCard
    ? {
        ...result.winningCard,
        src: `${bucketUrl}${result.winningCard.fileName}.jpg`,
      }
    : undefined;

  try {
    [...randomCards, winningCard].map((card) => {
      const img = new Image();
      img.src = card?.src;
    });
  } catch (error) {}

  return { ...result, randomCards, winningCard };
};
