import { useMemo, useState } from "react";

import { useWallet } from "use-wallet";

import { useContractOptions } from "./useContract.types";

export async function getContract(provider: any, contract: any) {
  await contract.setProvider(provider);

  return contract;
}

export default function useContract(
  contract: any,
  options: useContractOptions = {}
): any {
  const wallet = useWallet();
  const [deployed, setDeployed] = useState<any>();

  useMemo(async () => {
    if (contract && wallet.ethereum && deployed?.address !== options.address) {
      await contract.setProvider(wallet.ethereum);

      if (options.deployed) {
        setDeployed(await contract.deployed());
      } else if (options.address) {
        const d = await contract.at(options.address);

        setDeployed(d);
      }
    }
  }, [!!contract, options.address, deployed?.address, wallet.status]);

  return deployed;
}
