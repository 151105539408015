import React, { CSSProperties } from "react";

import * as styles from "./neon.module.scss";

interface Props {
  style?: CSSProperties;
  sign: Neon[];
}

export type Neon = {
  characters: string;
  blinkClass?: string;
};

export default ({ sign, style }: Props) => {
  return (
    <div className={styles.neon}>
      <b style={style}>
        {sign.map((item, index) => (
          <span
            key={`${index}-${item.characters}`}
            className={`text-6xl ${
              item.blinkClass ? styles[item.blinkClass] : ""
            }`}
          >
            {item.characters}
          </span>
        ))}
      </b>
    </div>
  );
};
