import React from "react";
import { useMemo } from "react";
import { XIcon } from "@heroicons/react/outline";

interface StatusBarProps {
  type?: string;
  content?: any;
  onClose?: () => void;
}

export const StatusBar = ({
  type = "default",
  content,
  onClose,
}: StatusBarProps) => {
  const colorClass = useMemo(() => {
    switch (type) {
      case "error":
        return "bg-red-800";
      default:
        return "bg-black";
    }
  }, [type]);

  return (
    <div className={`${colorClass} w-full px-3 py-2 flex text-slate-300`}>
      <div className="flex-1">{content}</div>
      {onClose && (
        <div className="flex-none">
          <button onClick={onClose}>
            <XIcon className="h-4" />
          </button>
        </div>
      )}
    </div>
  );
};
