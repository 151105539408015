import React from "react";
import Neon from "../components/neon";

export enum CharacterNames {
  Jimmy = "Jimmy",
  Sera = "Sera",
  Terry = "Terry",
  Renee = "Renee",
  CaptainBobbie = "Captain Bobbie",
  Linus = "Linus",
  Elle = "Elle",
  Jan = "Jan",
  Perkus = "Perkus",
  Brady = "Brady",
  Lana = "Lana",
  Jessie = "Jessie",
  Meli = "Meli",
  Rand = "Rand",
}

type CharacterInfo = {
  [key in CharacterNames]: {
    neon: any;
  };
};

export const CharacterInfo: CharacterInfo = {
  Jimmy: {
    neon: (
      <Neon
        sign={[
          { characters: "Jimm" },
          { characters: "y", blinkClass: "doBlink4" },
          { characters: " - " },
          { characters: "The B" },
          { characters: "o", blinkClass: "doBlink5" },
          { characters: "okie " },
        ]}
      />
    ),
  },
  Sera: {
    neon: (
      <Neon
        sign={[
          { characters: "Ser" },
          { characters: "a", blinkClass: "doBlink4" },
          { characters: " - " },
          { characters: "The Dr" },
          { characters: "i", blinkClass: "doBlink5" },
          { characters: "ver " },
        ]}
      />
    ),
  },
  Terry: {
    neon: (
      <Neon
        sign={[
          { characters: "Terr" },
          { characters: "y", blinkClass: "doBlink4" },
          { characters: " - " },
          { characters: "The " },
          { characters: "T", blinkClass: "doBlink5" },
          { characters: "herapist " },
        ]}
      />
    ),
  },
  Renee: {
    neon: (
      <Neon
        sign={[
          { characters: "Re" },
          { characters: "n", blinkClass: "doBlink4" },
          { characters: "ee" },
          { characters: " - " },
          { characters: "The Es" },
          { characters: "c", blinkClass: "doBlink5" },
          { characters: "ort " },
        ]}
      />
    ),
  },
  "Captain Bobbie": {
    neon: (
      <Neon
        sign={[
          { characters: "Cap" },
          { characters: "t", blinkClass: "doBlink3" },
          { characters: "ain " },
          { characters: "B", blinkClass: "doBlink4" },
          { characters: "obbie" },
          { characters: " - " },
          { characters: "The Ai" },
          { characters: "r", blinkClass: "doBlink5" },
          { characters: " Traffic Controller " },
        ]}
      />
    ),
  },
  Linus: {
    neon: (
      <Neon
        sign={[
          { characters: "Linu" },
          { characters: "s", blinkClass: "doBlink4" },
          { characters: " - " },
          { characters: "The De" },
          { characters: "a", blinkClass: "doBlink5" },
          { characters: "ler " },
        ]}
      />
    ),
  },
  Elle: {
    neon: (
      <Neon
        sign={[
          { characters: "Elle" },
          { characters: " - " },
          { characters: "The D" },
          { characters: "o", blinkClass: "doBlink5" },
          { characters: "ctor" },
        ]}
      />
    ),
  },
  Jan: {
    neon: (
      <Neon
        sign={[
          { characters: "Jan" },
          { characters: " - " },
          { characters: "The " },
          { characters: "Ho", blinkClass: "doBlink5" },
          { characters: "usekeeper" },
        ]}
      />
    ),
  },
  Perkus: {
    neon: (
      <Neon
        sign={[
          { characters: "Perkus" },
          { characters: " - " },
          { characters: "The Con" },
          { characters: "t", blinkClass: "doBlink5" },
          { characters: "ent Creator" },
        ]}
      />
    ),
  },
  Brady: {
    neon: (
      <Neon
        sign={[
          { characters: "Brady" },
          { characters: " - " },
          { characters: "The Res" },
          { characters: "o", blinkClass: "doBlink4" },
          { characters: "rt Mana" },
          { characters: "g", blinkClass: "doBlink5" },
          { characters: "er" },
        ]}
      />
    ),
  },
  Lana: {
    neon: (
      <Neon
        sign={[
          { characters: "Lana" },
          { characters: " - " },
          { characters: "The C" },
          { characters: "y", blinkClass: "doBlink4" },
          { characters: "bersecurity A" },
          { characters: "g", blinkClass: "doBlink5" },
          { characters: "ent" },
        ]}
      />
    ),
  },
  Jessie: {
    neon: (
      <Neon
        sign={[
          { characters: "Jessie" },
          { characters: " - " },
          { characters: "The De" },
          { characters: "t", blinkClass: "doBlink5" },
          { characters: "ective" },
        ]}
      />
    ),
  },
  Meli: {
    neon: (
      <Neon
        sign={[
          { characters: "Meli" },
          { characters: " - " },
          { characters: "The Con" },
          { characters: "c", blinkClass: "doBlink5" },
          { characters: "ierge" },
        ]}
      />
    ),
  },
  Rand: {
    neon: (
      <Neon
        sign={[
          { characters: "Rand" },
          { characters: " - " },
          { characters: "The Ps" },
          { characters: "y", blinkClass: "doBlink5" },
          { characters: "chic" },
        ]}
      />
    ),
  },
};
