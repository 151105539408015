const ethers = require("ethers");
const units = require("ethereumjs-units");

const BigNumber = ethers.BigNumber;
const complexity = BigNumber.from("156291");

export default async function estimateGasEth() {
  let estimatedEth = -1;

  try {
    const response = await fetch("https://ethgas.watch/api/gas");

    const result = await response.json();

    const gwei = BigNumber.from(result.normal.gwei).mul(complexity);

    estimatedEth = units.convert(gwei, "gwei", "ether");
  } catch (e) {
    // fallback
    console.error(e);
  }

  return estimatedEth;
}
