import React, { Fragment, useEffect, useState, useCallback } from "react";
import { animated, useSpring, useTransition } from "react-spring";
import * as styles from "./slotMachine.module.scss";
import { CharacterInfo } from "../lib/characterInfo";
import { getWinningCard } from "../lib/fetchCard";
import emptyImage from "../images/emptyImage.png";
import { MintCharacterNeonSign, MintedNeonSign } from "./mintCharacterNeonSign";
import useContract from "../hooks/useContract";
import InsideWorldNft from "../contracts/insideWorldNFT";
import { useWallet } from "use-wallet";
import { MintButton } from "./mintButton";
import BN from "bignumber.js";
import web3 from "web3";
import useEstimatedGas from "../hooks/useEstamateGas";
import { StatusBar } from "./statusBar";
import { formatNumber } from "../lib/numbers";
import { useMemo } from "react";

enum SlotMachineState {
  Initial,
  Rolling,
  Finished,
  NoCards,
}

const InsideWorldNFTAddress = process.env
  .GATSBY_INSIDE_WORLD_NFT_ADDRESSS as string;

const bucketUrl = process.env.GATSBY_IMAGE_BUCKET_URL as string;

const placeholderCards = [
  { src: `${bucketUrl}1.jpg` },
  { src: `${bucketUrl}2.jpg` },
  { src: `${bucketUrl}3.jpg` },
];

export default ({}: any) => {
  const [state, setState] = useState<SlotMachineState>(
    SlotMachineState.Initial
  );

  const [winning, setWinning] = useState<any>();
  const [images, setImage] = useState<Array<any>>(placeholderCards);
  const [counter, setCounter] = useState(0);
  const [minting, setMinting] = useState(false);
  const [minted, setMinted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [winnerSign, setWinnerSign] = useState();
  const gasFee = useEstimatedGas();

  const wallet = useWallet();
  const contract = useContract(InsideWorldNft, {
    address: InsideWorldNFTAddress,
  });

  const fetchWinningCard = useCallback(async () => {
    try {
      const result = await getWinningCard(winning?.cardId);

      if (result.winningCard) {
        setImage((imgs) => [...imgs, ...result.randomCards]);
        setWinning(result.winningCard);
        setWinnerSign(CharacterInfo[result.winningCard?.character]?.neon);
      } else {
        setState(SlotMachineState.NoCards);
      }
    } catch (error) {
      console.error(error);
      setState(SlotMachineState.NoCards);
    }
  }, [winning]);

  const onRoll = () => {
    if (state === SlotMachineState.Rolling) {
      return setState(SlotMachineState.Finished);
    }

    setState(SlotMachineState.Rolling);

    api.start({
      from: { speed: 100, filter: "blur(50px)" },
      to: { speed: 1000, filter: "blur(7px)" },
    });
  };

  const onReRoll = () => {
    fetchWinningCard();
    onRoll();
    setWinning(undefined);
    setMinted(false);
  };

  const onMint = useCallback(async () => {
    try {
      setMinting(true);

      if (wallet.account && winning != undefined) {
        await contract.mint(wallet.account, winning.cardId, {
          from: wallet.account,
          value: new BN(web3.utils.toWei("0.1", "ether").toString()),
        });

        setMinted(true);
      }
    } catch (error) {
      console.error(error);
      switch (error?.code) {
        case 4001:
          return;
        case -32000:
          setErrorMessage(
            "Please deposit ETH into your wallet, insufficient funds to complete transaction."
          );
          return;
        default:
          setErrorMessage("Something went wrong, please try againg later");
          break;
      }
    } finally {
      setMinting(false);
    }
  }, [wallet.account, winning, contract?.address]);

  const [speed, api] = useSpring(() => ({
    config: {
      duration: 4000,
    },
    from: { speed: 100, filter: "blur(50px)" },
  }));

  const transitions = useTransition(counter, {
    key: counter,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 0 },
    exitBeforeEnter: false,
  });

  const headerState = useMemo(() => {
    if (minted) {
      return <MintedNeonSign key="minted" />;
    } else if (state === SlotMachineState.Finished) {
      return winnerSign;
    } else {
      return <MintCharacterNeonSign key="default" />;
    }
  }, [minted, state]);

  const headerTransitions = useTransition(headerState, {
    key: headerState.key,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1500 },
    exitBeforeEnter: true,
  });

  const winnerTransitions = useTransition(state === SlotMachineState.Finished, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1500 },
    exitBeforeEnter: true,
  });

  useEffect(() => {
    if (state === SlotMachineState.Rolling) {
      if (speed.speed.get() == 1000 && winning !== undefined) {
        setState(SlotMachineState.Finished);
        api.start({
          to: { speed: 1000, filter: "blur(7px)" },
        });
      } else {
        const timeout = setTimeout(() => {
          setCounter((count) => (count < images.length - 1 ? count + 1 : 0));
        }, speed.speed.get());

        return () => clearTimeout(timeout);
      }
    }
    if (
      state === SlotMachineState.Finished ||
      state === SlotMachineState.NoCards
    ) {
      api.start({ to: { filter: "blur(0px)" }, config: { duration: 500 } });
    }
  }, [counter, state, winning]);

  //pre fetch images
  useEffect(() => {
    placeholderCards.map((card) => {
      const img = new Image();
      img.src = card.src;
    });
  }, []);

  return (
    <Fragment>
      <div className="pt-10">
        {headerTransitions((styles, item) => (
          <animated.div style={styles}>{item}</animated.div>
        ))}
      </div>
      <div className={styles.container}>
        <img className={styles.placeholder} src={emptyImage} />
        {transitions((style, item) => {
          if (
            state === SlotMachineState.Finished ||
            (speed.speed.get() == 1000 && winning !== undefined)
          ) {
            return (
              <animated.img
                className={styles.image}
                src={winning.src}
                style={{
                  ...style,
                  ...speed,
                }}
              />
            );
          }

          if (state === SlotMachineState.NoCards) {
            return (
              <animated.div
                className={styles.noCard}
                style={{
                  ...style,
                  ...speed,
                }}
              >
                <div>
                  <h2 className="text-center text-white">
                    No more cards available
                  </h2>
                </div>
              </animated.div>
            );
          }

          return (
            <animated.img
              className={styles.image}
              src={images[item].src}
              style={{
                ...style,
                ...speed,
              }}
            />
          );
        })}
      </div>
      {winnerTransitions((style, item) => (
        <div className="w-full h-10 md:px-10 md:max-w-3xl lg:max-w-4xl">
          {state == SlotMachineState.Finished && item && (
            <animated.div style={style}>
              <div className={styles.subtitle}>
                <div>
                  <span>Location: </span>
                  <span>{winning?.backgroundName ?? winning.background}</span>
                </div>
                <div>
                  <span>Props: </span>
                  <span>{winning?.objects?.join(", ")}</span>
                </div>
              </div>
              <div className={styles.underline} />
            </animated.div>
          )}
        </div>
      ))}
      <div>
        {state === SlotMachineState.Finished && !minted ? (
          <MintButton onClick={onMint} label={"Mint"} loading={minting} />
        ) : null}

        <button
          className="mx-2 relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800"
          onClick={onReRoll}
          disabled={minting || state === SlotMachineState.Rolling}
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Shuffle
          </span>
        </button>
      </div>

      {errorMessage ? (
        <StatusBar
          type="error"
          content={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      ) : (
        <StatusBar
          content={`0.1 ETH card cost  ${
            gasFee.loading
              ? " = 0.1 ETH"
              : `+ ${formatNumber(
                  gasFee.value
                )} ETH gas fee (estimate) = ${formatNumber(
                  new BN(gasFee.value).plus(0.1).toNumber()
                )} ETH`
          } `}
        />
      )}
    </Fragment>
  );
};
