import React from "react";
import * as styles from "./home.module.scss";
import { Helmet } from "react-helmet";
import placeholder from "../images/placeholderCard.jpeg";
import CSlotMachine from "../components/slotMachine";

// markup
const IndexPage = () => {
  return (
    <main className={styles.container}>
      <Helmet>
        <meta property="og:title" content="theINSIDEworld" />
        <meta property="og:image" content={placeholder} />
      </Helmet>
      <CSlotMachine />
    </main>
  );
};

export default IndexPage;
