import React from "react";
import Neon from "./neon";

export const MintCharacterNeonSign = () => {
  return (
    <Neon
      sign={[
        { characters: "M" },
        { characters: "in", blinkClass: "doBlink4" },
        { characters: "t " },
        { characters: "y" },
        { characters: "o", blinkClass: "doBlink5" },
        { characters: "ur " },
        { characters: "car" },
        { characters: "d", blinkClass: "doBlink6" },
      ]}
    />
  );
};

export const MintedNeonSign = () => {
  return (
    <Neon
      sign={[
        { characters: "M" },
        { characters: "in", blinkClass: "doBlink4" },
        { characters: "te" },
        { characters: "d", blinkClass: "doBlink5" },
      ]}
    />
  );
};
