import React from 'react';

import { createPortal } from 'react-dom';
import cn from 'classnames';

import CloseIcon from '../../images/close.png';

import * as styles from './Modal.module.scss';

interface IModal {
  className?: string;
  children: React.ReactNode;
  closeModal: () => void;
}

const Modal = ({ className, children, closeModal }: IModal) => {
  return createPortal(
    <div className={styles.modal}>
      <div
        className={styles.modalClose}
        onClick={closeModal}
        role="presentation"
        aria-label="Close Modal"
      ></div>
      <div className={cn(styles.modalInner, className)}>
        <div className={styles.modalCloseBtn}>
          <button onClick={closeModal}>
            <img src={CloseIcon} width="15px" height="15px" alt="Close"></img>
          </button>
        </div>
        {children}
      </div>
    </div>,
    document.getElementById('___gatsby')!,
  );
};

export default Modal;
